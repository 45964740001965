
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatBadge1(badge, badgeIndex) {
                    return _createElement('div', {
                        'className': 'product-label-wrapper flex',
                        'key': badge
                    }, _createElement('span', { 'className': 'product-label product-label--custom cm_badge cm_badge__' + this.toKebabCase(badge) }, '\n            ', badge, '\n          '));
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', {}, _createElement('product-card', { 'class': 'card card--product h-full card--product-compare relative flex' }, _createElement('div', { 'className': 'card__media relative' + (this.image2 ? ' has-secondary-image' : '') }, _createElement('a', {
                    'href': this.url,
                    'aria-label': this.removeHTML(this.title),
                    'className': 'media block relative image-blend js-prod-link',
                    'tabIndex': '-1'
                }, _createElement('img', {
                    'className': 'img-fit card__main-image card__main-image_primary no-js-hidden',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'data-media-id': this.id,
                    'loading': 'lazy',
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'img-fit card__main-image no-js-hidden card__main-image_secondary',
                    'src': this.resizeImage(this.image2),
                    'data-media-id': this.id,
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '592'
                }) : null), _createElement.apply(this, [
                    'div',
                    { 'className': 'product-label-container absolute top-0 start' },
                    this.out_of_stock ? _createElement('div', {
                        'className': 'product-label-wrapper flex',
                        'key': '937'
                    }, _createElement('span', { 'className': 'product-label product-label--sold-out' }, 'Sold out')) : null,
                    this._badges ? _map(this._badges, repeatBadge1.bind(this)) : null
                ])), _createElement('div', { 'className': 'card__info-container flex flex-col flex-auto relative' }, _createElement('div', { 'className': 'card__info w-full' }, _createElement('div', { 'className': 'card__info-inner inline-block w-full' }, _createElement('p', mergeProps({ 'className': 'card__vendor mb-1 text-sm text-theme-light' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('p', { 'className': 'card__title font-bold mb-1' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'card-link text-current js-prod-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), this.stock?.includes('In-stock Only') ? _createElement('div', {
                    'className': 'card__stock',
                    'key': '1907'
                }, [_createElement('svg', {
                        'className': 'cm_icon cm_icon-check',
                        'height': '20px',
                        'role': 'img',
                        'viewBox': '0 0 492 492',
                        'key': '20010'
                    }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))], _createElement('span', { 'className': 'card__stock-text' }, 'In Stock')) : null, _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__default' }, this.price_varies ? _createElement('span', { 'key': '2809' }, 'From: ') : null, _createElement('strong', { 'className': 'price__current' }, this.formatPrice(this.price)), this.on_sale ? _createElement('s', {
                    'className': 'price__was',
                    'key': '2954'
                }, this.formatPrice(this.compare_at_price)) : null, this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item--set',
                    'key': '3057'
                }, '\n                Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n              ') : null)))), _createElement('div', { 'className': 'card__quick-add mob:card__quick-add--below desktop:card__quick-add--below' }, _createElement('product-form', {}, _createElement('div', {
                    'className': 'alert mb-8 bg-error-bg text-error-text js-form-error text-start',
                    'role': 'alert',
                    'hidden': true
                }), _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': 'quick-add-' + this.id,
                    'acceptCharset': 'UTF-8',
                    'className': 'js-product-form',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.id
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': this.set_qty || 1
                }), this.out_of_stock || !this.out_of_stock && this.variant_ids.length === 1 ? _createElement('button', {
                    'className': 'btn btn--primary w-full',
                    'data-add-to-cart-text': 'Add to cart',
                    'name': 'add',
                    'aria-haspopup': 'dialog',
                    'disabled': this.out_of_stock,
                    'key': '4081'
                }, _createElement('span', { 'className': 'quick-add-btn-text' }, this.out_of_stock ? ['Sold out'] : null, !this.out_of_stock ? [
                    '\n                  Add to cart',
                    this.set_qty ? ` Set of ${ this.set_qty }` : '',
                    '\n                '
                ] : null)) : null)), !this.out_of_stock && this.variant_ids.length > 1 ? _createElement('button', {
                    'type': 'button',
                    'className': 'btn btn--primary w-full js-quick-add',
                    'aria-haspopup': 'dialog',
                    'data-product-url': this.url,
                    'data-product-default-variant': 'false',
                    'data-quick-add-listener-added': 'true',
                    'key': '4710'
                }, _createElement('span', { 'className': 'quick-add-btn-text block pointer-events-none' }, 'Choose options')) : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'h4 mt-10 cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'ul',
        { 'className': 'slider slider__grid grid grid-flow-col auto-cols-2 small-cards-mobile md:auto-cols-3 lg:auto-cols-4 xl:auto-cols-5 small-cards-desktop gap-x-theme gap-y-8 cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []